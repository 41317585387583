<template>
  <section>
    <div
      v-if="post?._id" 
      class="row">
      <div class="col-6">
        <h5>
          <span
            class="circle"
            :class="post.status" />
          {{ $t('calendar.status.' + post.status) }}
        </h5>
      </div>
      <div class="col-6">
        <span class="scheduleTo">
          {{ $t('compose.scheduled_for.' + post.status) }}
          <span
            style="font-weight: bold;">
            {{ post.scheduledAt | timestampToDateString }}
          </span>
        </span>
      </div>
    </div>
    <div
      class="card">
      <div class="card-header d-flex align-items-center border-bottom py-3">
        <div class="d-flex align-items-center">
          <a>
            <img
              src="/src/assets/img/megaphone3.png"
              class="avatar"
              alt="HAJICON - Flaticon">
          </a>
          <div class="mx-3">
            <h3 class="mb-0 title-card">
              {{ $tc('compose.title') }}
            </h3>
            <p class="mb-0 subtitle-card">
              {{ $tc('compose.subtitle') }}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="post"
        class="card-body pb-0">
        <div class="row">
          <div
            class="col-12 col-xl-6">
            <div
              v-if="socialsSelected.some(obj => obj.socialBrand === 'instagram')"
              class="container_toggle">
              <button
                class="toggle-btn w-3"
                :class="post.publishType === 'REELS' ? 'active' : ''"
                :disabled="postDisabled"
                @click="post.publishType = 'REELS'">
                {{ $tc('compose.publish_type_creation.reels') }}
              </button>
              <button
                class="toggle-btn w-3"
                :class="post.publishType === 'IMAGE/VIDEO' ? 'active' : ''"
                :disabled="postDisabled"
                @click="post.publishType = 'IMAGE/VIDEO'">
                {{ $tc('compose.publish_type_creation.post') }}
              </button>
              <button
                class="toggle-btn w-3"
                :class="post.publishType === 'STORIES' ? 'active' : ''"
                :disabled="postDisabled"
                @click="post.publishType = 'STORIES'">
                {{ $tc('compose.publish_type_creation.story') }}
              </button>
            </div>
          </div>
          <div
            class="col-12 col-xl-6 mt-2 mt-xl-0">
            <labels
              @update-labels="updateLabels" />
            <a
              class="float-end mt-0 manage-tags"
              style="font-size: 14px;"
              @click="redirectToTagsView"> Manager les étiquettes </a>
          </div>
        </div>
        <b-form-textarea
          id="post-input"
          v-model="post.message"
          :placeholder="$tc('compose.textarea_placeholder')"
          rows="10"
          no-resize
          :disabled="loadingAction || postDisabled"
          class="mb-2 mt-4 bg-light"
          :class="(errorImgRequired || errorCaptionLength || errorHashtagsLength) && validating ? 'red-border' : ''" />
        <p
          v-if="errorImgRequired && validating"
          class="text-error">
          {{ $tc('compose.errors.ig_image_required') }}
        </p>
        <p
          v-if="errorCaptionLength && validating"
          class="text-error">
          {{ $tc('compose.errors.media_2207010') }}
        </p>
        <p
          v-if="errorCaptionRequired && validating"
          class="text-error">
          {{ $tc('compose.errors.caption_required') }}
        </p>
        <p
          v-if="errorHashtagsLength && validating"
          class="text-error">
          {{ $tc('compose.errors.media_2207040') }}
        </p>
        <hr class="horizontal dark my-3">
        <div
          v-if="post.publishType === 'STORIES' && socialsSelected.some(obj => obj.socialBrand === 'instagram')"
          class="card mb-3">
          <div class="card-body border-radius-lg bg-gradient-dark p-3">
            <h6 class="mb-0 text-white">
              {{ $tc('compose.errors.ratio_title') }}
            </h6>
            <p class="text-white text-sm">
              {{ $tc('compose.errors.ratio_text') }}
            </p>
          </div>
        </div>
        <div class="row align-items-center px-2">
          <div class="col-sm-6">
            <div class="d-flex">
              <b-button
                v-b-tooltip.hover
                :title="$tc('compose.tooltip_upload')"
                variant="link"
                class="text-dark pb-0 px-2"
                :disabled="loadingAction || postDisabled || mediaUploadProcessing || (post.medias.length > 0 && post.medias[0].mimeType.includes('video'))"
                @click="showUploadModal">
                <font-awesome-icon
                  class="icon-footer"
                  :icon="['fas', 'images']" />
              </b-button>
              <b-button
                v-b-tooltip.hover
                :title="$tc('compose.tooltip_emoji')"
                variant="link"
                class="text-dark pb-0 px-2"
                :disabled="loadingAction || postDisabled"
                @click.stop="setEmojiPicker()">
                <font-awesome-icon
                  class="icon-footer"
                  :icon="['far', 'smile']" />
              </b-button>
              <v-emoji-picker
                v-show="isEmojiPickerVisible"
                ref="emojiPicker"
                class="emoji-picker"
                emojis-by-row="8"
                emoji-size="24"
                @click.stop
                @select="selectEmoji" />
              <b-button
                v-b-tooltip.hover
                :title="$tc('compose.tooltip_hashtag')"
                variant="link"
                class="text-dark pb-0 px-2"
                :disabled="loadingAction || postDisabled"
                @click.stop="generateHashtags()">
                <font-awesome-icon
                  class="icon-footer"
                  :icon="['fas', 'hashtag']" />
              </b-button>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="d-flex align-items-center justify-content-sm-end">
              <b-dropdown
                id="tone-dropdown"
                class="btn btn-outline-primary btn-primary  mt-1 mx-2 float-end"
                :disabled="loadingAction || postDisabled">
                <template
                  #button-content>
                  <template
                    v-if="selectedTone">
                    <img
                      v-if="selectedTone.name === 'casual'"
                      class="tone-emoji"
                      :alt="selectedTone.name"
                      src="@/assets/img/casual.png">
                    <img
                      v-if="selectedTone.name === 'formal'"
                      class="tone-emoji"
                      :alt="selectedTone.name"
                      src="@/assets/img/formal.png">
                    <img
                      v-if="selectedTone.name === 'friendly'"
                      class="tone-emoji"
                      :alt="selectedTone.name"
                      src="@/assets/img/friendly.png">
                    <img
                      v-if="selectedTone.name === 'exclusive'"
                      class="tone-emoji"
                      :alt="selectedTone.name"
                      src="@/assets/img/exclusive.png">
                    {{ $tc(`comment.tone.${selectedTone.name}`) }}
                  </template>
                  <template v-else>
                    <img
                      class="tone-emoji"
                      alt="casual"
                      src="@/assets/img/casual.png">
                    {{ $tc('comment.tone.select') }}
                  </template>
                </template>
                <b-dropdown-item
                  v-for="tone in tonesList"
                  :key="tone.name">
                  <div
                    @click="selectedTone = tone">
                    <img
                      v-if="tone.name === 'casual'"
                      class="tone-emoji"
                      :alt="tone.name"
                      src="@/assets/img/casual.png">
                    <img
                      v-if="tone.name === 'formal'"
                      class="tone-emoji"
                      :alt="tone.name"
                      src="@/assets/img/formal.png">
                    <img
                      v-if="tone.name === 'friendly'"
                      class="tone-emoji"
                      :alt="tone.name"
                      src="@/assets/img/friendly.png">
                    <img
                      v-if="tone.name === 'exclusive'"
                      class="tone-emoji"
                      :alt="tone.name"
                      src="@/assets/img/exclusive.png">
                    {{ $tc(`comment.tone.${tone.name}`) }}
                  </div>
                </b-dropdown-item>
              </b-dropdown>
              <b-button
                variant="primary"
                class="text-white generate-hashtags"
                :disabled="loadingAction || postDisabled"
                @click.stop="generatePost()">
                <i class="fas fa-magic me-1" />
                {{ $tc('compose.rewriteBtn') }}
              </b-button>
            </div>
          </div>
        </div>
        <loading-icon
          v-if="mediaUploadProcessing"
          :margin="''" />
        <div
          v-if="post?.medias.length > 0"
          class="row mb-4">
          <div
            v-for="(file, index) in post.medias"
            :key="'file_' + index"
            class="col-3 image-container">
            <video
              v-if="file.mimeType.includes('video')"
              class="img-fluid rounded mt-2">
              <source
                :src="file.url"
                :type="file.mimeType">
              {{ $tc('compose.errors.video_not_supported') }}
            </video>
            <img
              v-else
              :src="file.thumbnails[1]"
              class="img-fluid rounded mt-2"
              alt="image">
            <span
              class="remove-icon"
              @click="removeMedia(file)">
              <i class="fas fa-trash-alt" />
            </span>
          </div>
        </div>
        <b-button
          v-if="post && post.medias.length > 0 && post.publishType === 'REELS' && socialsSelected.some(obj => obj.socialBrand === 'instagram')"
          type="button"
          variant="primary"
          class="btn-outline-primary mx-2"
          :disabled="loadingAction || postDisabled"
          @click="thumbnailModal">
          {{ $tc('compose.thumbnail_title') }}
        </b-button>
      </div>
    </div>
    <div>
      <b-button
        type="button"
        variant="primary"
        class="btn btn-primary mt-4 float-end"
        :disabled="loadingAction || postDisabled"
        @click="instantPublish">
        {{ $tc('compose.instant_publish') }}
      </b-button>
      <b-button
        type="button"
        variant="primary"
        class="btn-outline-primary mt-4 mx-2 float-end"
        :disabled="loadingAction || postDisabled"
        @click="schedulePublish">
        {{ $tc('compose.schedule_publish') }}
      </b-button>
    </div>
    <upload-images
      v-if="post"
      ref="UploadImages"
      :medias="post.medias"
      @fileUploaded="fileUploaded"
      @imageSelected="imageSelected" />
    <crop-image-modal
      ref="cropImageModal"
      :image="selectedFile"
      :post="post"
      @save-image="saveImage" />
    <b-modal
      id="schedulePostModal"
      ref="schedulePostModal"
      hide-footer
      :title="$tc('compose.schedule_title')">
      <div class="row">
        <div class="col-12">
          <schedule-modal
            v-if="post"
            :post="post"
            :socials-selected="socialsSelected"
            @hideModal="$refs.schedulePostModal.hide()"
            @schedulePost="schedulePost" />
        </div>
      </div>
    </b-modal>
    <b-modal
      v-if="post && post.medias.length > 0 && post.publishType === 'REELS' && socialsSelected.some(obj => obj.socialBrand === 'instagram')"
      id="thumbnailModal"
      ref="thumbnailModal"
      hide-footer
      :title="$tc('compose.thumbnail_title')">
      <div class="row">
        <div class="col-12">
          <thumbnail-modal
            :video-src="post.medias[0].url"
            @thumbnailReel="setThumbnailReel"
            @hideModal="$refs.thumbnailModal.hide()" />
        </div>
      </div>
    </b-modal>
    <confirmation-modal ref="confirmDialogue" />
  </section>
</template>
<script>
import { VEmojiPicker } from 'v-emoji-picker';
import { faImages,faHashtag } from '@fortawesome/free-solid-svg-icons'
import { faSmile } from '@fortawesome/free-regular-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(faImages, faSmile, faHashtag)
import { generatePost, getHashtags } from "@/apis/ChatGpt";
import UploadImages from '@/views/Components/UploadImages.vue';
import CropImageModal from '@/views/Components/CropImageModal.vue';
import LoadingIcon from "@/views/Components/loadingIcon.vue";
import ScheduleModal from "@/views/ComposeFeed/ScheduleModal.vue";
import ThumbnailModal from "@/views/ComposeFeed/ThumbnailModal.vue";
import labels from "@/views/ComposeFeed/Labels.vue";
import {uploadMedia, deleteMedia} from "@/apis/MediaApi";
import ConfirmationModal from '@/views/Components/ConfirmationModal.vue'

import Vue from 'vue'
import { publishPost } from "@/apis/FacebookApi";

export default {
  name: 'NewFeed',
  components: {
    ScheduleModal,
    LoadingIcon,
    VEmojiPicker,
    UploadImages,
    CropImageModal,
    labels,
    ThumbnailModal,
    ConfirmationModal
  },
  props: {
    socialsSelected: {
      type: Array,
      default: () => []
    },
    defaultPost: {
      type: Object,
      default: null
    },
    postDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      socials: [],
      mediaUploadProcessing: false,
      validating: false,
      loadingAction: false,
      isEmojiPickerVisible: false,
      selectedFile: '',
      tonesList: [
         {
           img: 'casual.png',
            name: 'casual'
         },
         {
           img: 'formal.png',
            name: 'formal'
         },
         {
           img: 'friendly.png',
            name: 'friendly'
         },
         {
           img: 'exclusive.png',
            name: 'exclusive'
         }
       ],
      post: null,
      selectedTone: null
    };
  },
  computed: {
    errorImgRequired: function() {
      return this.post?.medias.length === 0 && this.socialsSelected.some(obj => obj.socialBrand === 'instagram')
    },
    errorCaptionLength: function() {
      return this.post?.message.length > 2200
    },
    errorCaptionRequired: function() {
      return this.post?.message.length === 0 && this.post.medias.length === 0
    },
    errorHashtagsLength: function() {
      const matches = this.post?.message.match(/#\w+/g)
      return (matches
? matches.length
: 0) > 5
    }
  },
  watch: {
    post: {
      handler: function(val) {
        this.$emit('update-post', val)
      },
      deep:true
    }
  },
  mounted() {
    this.post = this.defaultPost
    document.addEventListener('click', this.checkOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.checkOutsideClick);
  },
  methods: {
    setThumbnailReel(time) {
      this.post.thumbOffset = time * 1000
    },
    thumbnailModal() {
      this.$refs.thumbnailModal.show()
    },
    generateHashtags(){
      if (this.post.message.length > 20) {
        this.$emit('loading', true)
        getHashtags(this.post.message).then(res => {
          this.post.message += '\n' + res.data.data
          this.$emit('loading', false)
        })
        .catch(e => {
          this.$emit('loading', false)
          console.log(e)
        })
      } else {
        Vue.toasted.show(this.$t('compose.errors.too_short'), { type: 'error' })
      }
    },
    generatePost() {
      if (this.post.message.length > 20) {
        this.$emit('loading', true)
        generatePost(this.post.message, this.selectedTone)
          .then(res => {
            this.post.message =  res.data.data
            this.$emit('loading', false)
          })
          .catch(e => {
            this.$emit('loading', false)
            console.log(e)
          })
      } else {
        Vue.toasted.show(this.$t('compose.errors.too_short'), { type: 'error' })
      }
    },
    openModalSchedule() {
      this.$refs.schedulePostModal.show()
    },
    hide() {
      this.$refs.schedulePostModal.hide()
    },
    setEmojiPicker() {
      this.isEmojiPickerVisible = !this.isEmojiPickerVisible;
    },
    checkOutsideClick(event) {
      const emojiPicker = this.$refs.emojiPicker;
      if (emojiPicker && !emojiPicker.$el.contains(event.target)) {
        this.isEmojiPickerVisible = false;
      }
    },
    selectEmoji(emoji) {
      this.post.message = this.post.message + emoji.data;
    },
    showUploadModal() {
      this.$refs.UploadImages.show()
    },
    fileUploaded(file) {
      const current = this
      if (file) {
          if (typeof FileReader === 'function') {
              this.mime_type = file.type
              const reader = new FileReader()
              reader.onload = (event) => {
                  this.selectedFile = event.target.result
                  if (file.type.includes('video')){
                    this.mediaUploadProcessing = true
                    current.uploadMediaLocal(file);
                  } else {
                    this.$nextTick(function () {
                        this.$refs.cropImageModal.show()
                    })
                  }
              }
              reader.readAsDataURL(file)
          } else {
              alert('Sorry, FileReader API not supported')
          }
      }

    },
    imageSelected(image) {
      this.selectedFile = image.urls.regular
      this.$nextTick(function () {
        this.$refs.cropImageModal.show()
      })
    },
    saveImage(cropper) {
      const current = this
      this.mediaUploadProcessing = true
      cropper.getCroppedCanvas().toDataURL()
      cropper.getCroppedCanvas().toBlob((blob) => {
        current.uploadMediaLocal(blob);
      }, this.mime_type)
    },
    uploadMediaLocal(blob) {
      const current = this
      const formData = new FormData()
      formData.append('file', blob, 'profile_picture')
      formData.append('filename', 'profile_picture')
      formData.append('public', 'true')
      uploadMedia(formData)
        .then(response => {
          current.post.medias.push(response.createdMedia)
        })
        .catch(e => {
          console.log(e)
          if (e.response && e.response.data && e.response.data.message === 'Not supported MIME type') {
            Vue.toasted.show(this.$t('message.savingErrors.fileNotSupported'), { type: 'error' })
          } else {
            Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
          }
        })
        .finally(() => {
          this.mediaUploadProcessing = false
        })
    },
    async removeMedia(media) {
      const current = this
      const ok = await this.$refs.confirmDialogue.show({
        message: this.$tc('confirmationModal.imageDeletion')
      })
      if (ok) {
        deleteMedia(media._id)
            .then(response => {
              current.post.medias = current.post.medias.filter(item => item._id !== media._id)
            })
            .catch(e => {
              console.log(e)
            })
      }
    },
    redirectToTagsView() {
      this.$router.push({ name: 'Settings' })
    },
    updateLabels(labels) {
      this.post.labels = labels.map(item => item._id)
    },
    schedulePublish() {
      if (this.isValidate()) {
        this.openModalSchedule()
      }
    },
    instantPublish() {
      if (this.isValidate()) {
        this.$emit('loading', true)
        if (this.post.publishType === 'IMAGE/VIDEO' && this.post.medias.length > 0 && this.post.medias[0].mimeType.includes('image')) {
          this.post.publishType = 'IMAGE'
        } else if (this.post.publishType === 'IMAGE/VIDEO' && this.post.medias.length > 0 && this.post.medias[0].mimeType.includes('video')) {
          this.post.publishType = 'VIDEO'
        }
        const promises = []
        for (const social of this.socialsSelected) {
          this.post.platformIds = [social.profileId]
          promises.push(publishPost(this.post))
        }
        Promise.all(promises)
          .then(() => {
            this.$emit('loading', false)
            this.post = {
              message: '',
              medias: [],
              publishType: 'IMAGE/VIDEO',
              labels: [],
              scheduled: false,
              scheduledAt: 1688633226000,
              isRecycled: false,
              isApproved: true
            }
            this.validating = false
            Vue.toasted.show(this.$tc('savingSuccess.post_published'), { type: 'success' })
          })
          .catch(e => {
            this.$emit('loading', false)
            console.log(e)
            if (e.response.status === 500) {
              Vue.toasted.show(this.$tc(`loadingErrors.${e.response.data.message}`), { type: 'error' })
            } else if (e.response.data.type && e.response.data.type === 'Media'){
              console.log(`compose.errors.media_${e.response.data.message}`)
              Vue.toasted.show(this.$tc(`compose.errors.media_${e.response.data.message}`), { type: 'error' })
            }
            else {
              Vue.toasted.show(this.$tc('loadingErrors.servererror'), { type: 'error' })
            }
          })
      }
    },
    schedulePost(scheduledAt) {
      const date = new Date(scheduledAt.selectedDate)
      date.setHours(scheduledAt.selectedTime.HH, scheduledAt.selectedTime.mm,0 )
      this.post.scheduledAt = date.getTime()
      this.post.scheduled = true
      this.hide()
      this.instantPublish()
    },
    isValidate() {
      this.validating = true
      this.$emit('validating')
      if (this.socialsSelected.length === 0 || this.errorImgRequired || this.errorCaptionLength || this.errorHashtagsLength || this.errorCaptionRequired) {
        return false
      }
      return true
    }
  }
}
</script>
<style lang="scss">
  .emoji-picker {
      position: absolute;
      z-index: 10;
      /* Adjust these values as needed */
      top: 50px;
      left: 0;
  }
  .icon-footer {
    font-size: 18px;
  }
  #post-input{
      background-color: #f7fafc !important;
      color: #59616f;
  }
  #post-input:focus {
      box-shadow: none !important;
      border: 1px solid #ced4da !important;
  }

#modal1 {
  .modal-header {
    border: none;
    .close {
      border: none;
      outline: none;
      background: none;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
  .modal-body{
    padding-top: 0;
  }
  /* Styles for the tabs */
  .custom-tabs {
    .nav-item a{
      border: none;
      margin: 0;
      color: #252b36;
      &.active {
        border-bottom: 2px solid #2D7DD2;
        color: #2D7DD2;
      }
    }
  }
}
.image-container {
  position: relative;
  display: inline-block;
}
.img-fluid {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.img-fluid:hover {
  filter: brightness(70%);
}
.remove-icon {
  cursor: pointer;
  position: absolute;
  bottom: 5px;
  right: 15px;
  display: none;
  color: #FF6B6C;
  padding: 5px;
  transition: opacity 0.3s ease;
}
.image-container:hover .remove-icon {
  display: block;
}
.manage-tags {
  font-size: 14px;
  color: #344767;
  text-decoration: underline;
  cursor: pointer;
}
.ai-loader {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}
  .container_toggle {
    width: 100%;
    border-radius: 0.75rem;
    color: #fff;
    padding: 0.25rem;
    background: #a4c4e9 !important;
    position: relative;
  }
  .toggle-btn {
    color: #344767;
    border-radius: 0.5rem;
    background-color: inherit;
    padding: 4px 0px;
    flex-grow: 1;
    white-space: nowrap;
    flex-basis: 0px;
    border: 0px;
    width: 33%;
    &.w-3 {
      color: #344767 !important;
      width: 33% !important;
    }
  }
  .toggle-btn.active {
    background-color: white !important;
  }
  .multiselect__tags {
    padding: 2.5px 40px 2.5px 8px !important
  }
  .show-labels-modal {
    top: -3px !important;
    right: 0px !important;
    position: absolute !important;
  }
  .btn-outline-primary {
    color: #2d7dd2 !important;
    background: transparent !important;
  }
  .generate-hashtags {
    background: linear-gradient(270deg, rgb(252 9 137) 0%, rgb(24 118 210) 100%) !important;
  }
  #tone-dropdown {
    padding: 9.4px 20px;
    button{
      padding: 0px !important;
      box-shadow: none;
      text-transform: uppercase !important;
      background: transparent !important;
      color: #2d7dd2 !important;
    }
  }
  .tone-emoji {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .scheduleTo {
    float: right;
    padding: 0px 20px;
    border: 1px solid #02235f;
    font-size: 18px;
    color: #02235f;
    border-radius: 5px;
  }
</style>
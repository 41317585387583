<template>
  <div>
    <b-modal
      ref="postDetailModal"
      hide-footer
      @hide="hide">
      <template #modal-header="{ close }">
        <h5>
          <span
            class="circle"
            :class="post.status" />
          {{ $t('calendar.status.' + post.status) }}
        </h5>
        <b-button
          type="button"
          aria-label="Close"
          class="close"
          @click="close()">
          ×
        </b-button>
      </template>
      <facebook-preview
        v-if="provider.socialBrand === 'facebook'"
        :key="post._id"
        :post="post"
        :provider="provider" />
      <instagram-preview
        v-if="provider.socialBrand === 'instagram'"
        :key="post._id"
        :post="post"
        :provider="provider" />
      <div class="row">
        <div class="col-12">
          <b-dropdown
            class="dropdown-box float-end pt-2"
            :dropup="true"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret>
            <template #button-content>
              <i
                class="fas fa-ellipsis-v"
                aria-hidden="true"
                style="color: #8392ab" />
            </template>
            <b-dropdown-item
              href="#"
              @click="redirectToPost">
              <i
                class="fas fa-sync me-2"
                style="color: #6aabfa" />
              {{ $tc('calendar.update') }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="post.status === 'scheduled' || post.status === 'draft'"
              href="#"
              @click="deletePost">
              <i
                class="fas fa-trash-alt me-2"
                style="color: #ef8ea5" />
              {{ $tc('calendar.delete') }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </b-modal>
    <confirmation-modal ref="confirmDialogue" />
  </div>
</template>
<script>
import Vue from "vue";
import { deletePost } from "@/apis/FacebookApi";
import FacebookPreview from "@/views/Components/FacebookPreview.vue";
import InstagramPreview from "@/views/Components/InstagramPreview.vue";
import {deleteMedia} from "@/apis/MediaApi";
import ConfirmationModal from "@/views/Components/ConfirmationModal.vue";
export default {
  name:"PostDetailModal",
  components: {
    ConfirmationModal,
    InstagramPreview,
    FacebookPreview,
  },
  props: {
    post: {
      type: Object,
      required: true
    },
    provider: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    hide() {
      this.$emit('hide');
      this.$refs.postDetailModal.hide();
    },
    show() {
      this.$refs.postDetailModal.show();
    },
    redirectToPost() {
      this.$router.push({ name: 'composePage', query: { defaultPost: this.post._id} })
    },
    async deletePost() {
      const ok = await this.$refs.confirmDialogue.show({
        message: this.$tc('confirmationModal.postDeletion')
      })
      if (ok) {
        await deletePost(this.post._id).then((response) => {
          this.$refs.postDetailModal.hide();
          this.$emit('refresh')
        })
          .catch((error) => {
            console.log(error);
            Vue.toasted.show(this.$tc('loadingErrors.servererror'), {type: 'error'})
          });
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/soft-ui-dashboard/variables.scss";

.container_toggle {
  width: 100%;
  background-color: rgb(59, 130, 246);
  border-radius: 0.75rem;
  color: #fff;
  padding: 0.25rem;
}
.toggle-btn {
  color: #fff;
  opacity: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-inline-start: 0.75rem;
  padding-inline-end: 0.75rem;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  -webkit-box-flex: 1;
  flex-grow: 1;
  white-space: nowrap;
  text-align: center;
  transition: all 0.1s ease-in-out 0s;
  flex-basis: 0px;
  background: transparent;
  border: 0px;
  width: 50%;
}

.toggle-btn.active {
  background-color: #1d4ed8 !important;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.25);
}
:deep(.modal-header) {
  border-bottom: none !important;
  padding-bottom: 0;
  h5 {
    font-size: 1.15rem;
    font-weight: 400;
    margin: 0;
  }
  .close, .close:hover, .close:focus {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000 !important;
    text-shadow: 0 1px 0 #fff;
    opacity: .5 !important;
    padding: 0;
    background-color: transparent !important;
    margin: 0;
    box-shadow: none;
  }
}
:deep(.dropdown) {
  background-color: transparent !important;
}
:deep(.dropdown .dropdown-menu:before) {
  content: "" !important;
}
</style>
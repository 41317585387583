<template>
  <!-- End Navbar -->
  <section>
    <loading-icon
      v-if="loading"
      :size="'medium'"
      :position="'center'"
      class="ai-loader" />
    <div
      :class="loading ? 'card-loading' : ''"
      class="container-fluid py-4">
      <div class="row">
        <div class="col-12 col-lg-8 col-md-12">
          <div class="row">
            <div class="col-12">
              <div>
                <div class="card-body row d-flex">
                  <Flicking
                    :options="{ align: 'prev', bound: true, circular: false }"
                    :plugins="plugins">
                    <social-round-icon
                      v-for="social in socials"
                      :key="social.id"
                      :social="social"
                      :disabled="post._id !== undefined"
                      :is-active="socialsSelected.map(x => x._id).includes(social._id)"
                      @is-clicked="changeSelectedSocial" />
                    <span
                      slot="viewport"
                      class="flicking-arrow-prev is-thin" />
                    <span
                      slot="viewport"
                      class="flicking-arrow-next is-thin" />
                  </Flicking>
                  <p
                    v-if="socialsSelected.length === 0 && isValidating"
                    class="text-error mb-0 ms-4">
                    {{ $tc('compose.errors.channel_required') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-4">
              <new-feed
                :key="post._id"
                :socials-selected="socialsSelected"
                :default-post="post"
                :post-disabled="postDisabled()"
                @update-post="updatePost"
                @validating="isValidating = true"
                @loading="isLoading" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-4">
          <div class="card">
            <div class="card-header d-flex align-items-center border-bottom py-3">
              <div class="d-flex align-items-center">
                <a>
                  <img
                    src="/src/assets/img/loop.png"
                    class="avatar"
                    alt="HAJICON - Flaticon">
                </a>
                <div class="mx-3">
                  <h3 class="mb-0 title-card">
                    {{ $tc('compose.preview_title') }}
                  </h3>
                  <p class="mb-0 subtitle-card">
                    {{ $tc('compose.preview_subtitle') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="card-body w-100">
              <p
                v-if="socialsSelected.length === 0"
                class="m-auto subtitle-card text-center">
                {{ $tc('compose.preview_missing') }}
              </p>
              <div
                v-if="post">
                <div
                  v-for="(social, index) in socialsSelected"
                  :key="index"
                  class="col-12">
                  <facebook-preview
                    v-if="social.socialBrand === 'facebook'"
                    class="mb-3"
                    :post="post"
                    :provider="social" />
                  <instagram-preview
                    v-if="social.socialBrand === 'instagram'"
                    class="mb-3"
                    :post="post"
                    :provider="social" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import NewFeed from "@/views/ComposeFeed/NewFeed.vue";
import { Arrow } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/arrow.css";
import FacebookPreview from "@/views/Components/FacebookPreview.vue";
import InstagramPreview from "@/views/Components/InstagramPreview.vue";
import SocialRoundIcon from "@/views/ComposeFeed/SocialRoundIcon.vue";
import { getSocialProviders, getPost } from "@/apis/FacebookApi";
import Vue from "vue"
import LoadingIcon from "@/views/Components/loadingIcon.vue";

export default {
  name:"composeOverview",
  components: {LoadingIcon, SocialRoundIcon, InstagramPreview, FacebookPreview, NewFeed},
  props: {
    defaultPost: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      socials: [],
      socialsSelected: [],
      plugins: [new Arrow()],
      post: {
        message: '',
        medias: [],
        status: 'draft',
        publishType: 'IMAGE/VIDEO',
        labels: [],
        scheduled: false,
        scheduledAt: new Date(),
        isRecycled: false,
        isApproved: true,
        thumbOffset: 0,
      },
      loading: true,
      isValidating: false
    }
  },
  async mounted() {
    if (this.defaultPost !== '') {
      this.loading = true;
      await getPost(this.defaultPost).then(res => {
        this.post = res.data.post;
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        Vue.toasted.show(this.$tc('loadingErrors.servererror'), { type: 'error' })
      });
    }
    this.socials = this.$store.getters['socialProvider/getSocialProviders']
    if (this.post?.platformIds?.length > 0) {
      this.socialsSelected = this.socials.filter(s => this.post.platformIds.includes(s.profileId));
    }
    this.loading = false;
  },
  methods: {
    changeSelectedSocial(social, isActive) {
      if (!this.socialsSelected.map(x => x._id).includes(social._id)) {
        this.socialsSelected.push(social);
      } else {
        this.socialsSelected = this.socialsSelected.filter(s => s._id !== social._id);
      }
    },
    updatePost(post) {
      this.post = post
    },
    isLoading(isLoading) {
      this.loading = isLoading
    },
    postDisabled() {
      return this.post?._id && (this.post?.status === 'published' || this.post?.status === 'finalized')
    }
  }

}
</script>
<style lang="scss">
.flicking-arrow-prev {
  left: -20px;
  width: 40px !important;
}
.flicking-arrow-next {
  right: -20px;
  width: 40px !important;
}

</style>
